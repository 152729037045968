import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})

export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  isSubmitting = false;
  errorMessage: string | null = null;
  inputType = 'password';
  visible = false;
  user: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) 
  
  {

    this.authService.me().subscribe(data => {
      this.user = data;
    });
        
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: this.passwordsMatch });
  }

  passwordsMatch(group: FormGroup): { [key: string]: boolean } | null {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { passwordsMismatch: true };
  }

  toggleVisibility(): void {
    this.visible = !this.visible;
    this.inputType = this.visible ? 'text' : 'password';
  }

  onSubmit(): void {
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.isSubmitting = true;
    this.errorMessage = null;

    const { currentPassword, newPassword } = this.changePasswordForm.value;

    this.authService.updatePassword(this.user.id, {
      oldPassword: currentPassword,
      newPassword: newPassword,
    }).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.changePasswordForm.reset();
        alert('Mot de passe changé avec succès.');
        this.router.navigate(['/auth/login']);
      },
      error: (error) => {
        this.isSubmitting = false;
        this.errorMessage = 'Erreur lors du changement du mot de passe. Veuillez réessayer.';
        console.error('Erreur:', error);
      }
    });
  }
}
