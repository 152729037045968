<div class="w-full h-auto bg-pattern flex flex-col items-center justify-center py-4 px-4">
    <div @fadeInUp class="card overflow-hidden w-full max-w-md">
      <div class="p-6 pb-0 flex flex-col items-center justify-center">
        <div class="fill-current text-center">
          <img class="w-32" src="assets/img/logo.png" alt="logo">
        </div>
      </div>
  
      <div class="text-center mt-4">
        <h2 class="title m-0">Changer le mot de passe</h2>
        <h4 class="body-2 text-secondary m-0">
          Veuillez saisir votre mot de passe actuel et le nouveau mot de passe.
        </h4>
      </div>
  
      <div [formGroup]="changePasswordForm" class="p-6 flex flex-col gap-4">
        <div class="flex flex-col">
          <mat-error *ngIf="errorMessage" class="text-center mb-2">
            {{ errorMessage }}
          </mat-error>
  
          <!-- Champ mot de passe actuel -->
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Mot de passe actuel</mat-label>
            <input [type]="inputType" formControlName="currentPassword" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Afficher" type="button">
              <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
              <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('currentPassword').hasError('required')">
              Ce champ est requis.
            </mat-error>
          </mat-form-field>
  
          <!-- Champ nouveau mot de passe -->
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Nouveau mot de passe</mat-label>
            <input [type]="inputType" formControlName="newPassword" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Afficher" type="button">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">
              Ce champ est requis.
            </mat-error>
          </mat-form-field>
  
          <!-- Champ confirmation mot de passe -->
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Confirmez le mot de passe</mat-label>
            <input [type]="inputType" formControlName="confirmPassword" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Afficher" type="button">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.hasError('passwordsMismatch')">
              Les mots de passe ne correspondent pas.
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- Bouton changer mot de passe -->
        <button [disabled]="isSubmitting || !changePasswordForm.valid" (click)="onSubmit()" color="primary" mat-raised-button type="button">
          {{ isSubmitting ? 'Changement en cours...' : 'Changer le mot de passe' }}
        </button>
      </div>
    </div>
  </div>
  