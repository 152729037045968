<div class="mt-6 flex flex-col md:flex-row md:items-start">
  <div class="flex-auto">
    <div class="card shadow-lg rounded-lg">
      <!-- Titre principal -->
      <div class="px-gutter py-6">
        <h2 class="mb-4 text-2xl font-semibold text-gray-700">
          Profil de {{ user.prenom }} {{ user.nom }}
        </h2>
        <mat-divider></mat-divider>
      </div>

       <!-- Informations utilisateur -->
      <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
        <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
          <mat-label>Prénom</mat-label>
          <input matInput [value]="user.prenom" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
          <mat-label>Nom</mat-label>
          <input matInput [value]="user.nom" readonly>
        </mat-form-field>

        
        <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto col-span-2">
          <mat-label>Email</mat-label>
          <input matInput [value]="user.email" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
          <mat-label>Téléphone</mat-label>
          <input matInput [value]="user.telephone" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
          <mat-label>Est-ce un courtier ?</mat-label>
          <input matInput [value]="user.lier ? 'Non' : 'Oui'" readonly>
        </mat-form-field>
      </div>

      <!-- Compagnies associées -->
      <div *ngIf="user.compagnies?.length > 0" class="px-gutter py-6">
        <h2 class="mb-4 text-xl font-medium text-gray-600">Compagnies associées</h2>
        <div class="flex flex-wrap gap-4">
          <div *ngFor="let compagnie of user.compagnies" class="bg-gray-50 border border-gray-200 p-3 rounded shadow-sm">
            {{ compagnie.raisonSociale }}
          </div>
        </div>
      </div>

      <!-- Points de vente associés -->
      <div *ngIf="user.detailEspaceUser?.length > 0" class="px-gutter py-6">
        <h2 class="mb-4 text-xl font-medium text-gray-600">Points de vente associés</h2>
        <div class="flex flex-wrap gap-4">
          <div *ngFor="let pointDeVente of user.detailEspaceUser" class="bg-gray-50 border border-gray-200 p-3 rounded shadow-sm">
            {{ pointDeVente.libelle }}
          </div>
        </div>
      </div>

      <!-- Options associées -->
      <div *ngIf="user.menuEspaceUser?.options?.length > 0" class="px-gutter py-6">
        <h2 class="mb-4 text-xl font-medium text-gray-600">Options associées</h2>
        <div class="flex flex-wrap gap-4">
          <div *ngFor="let option of options" class="bg-gray-50 border border-gray-200 p-3 rounded shadow-sm">
            {{ option }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
