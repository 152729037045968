import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { Router } from '@angular/router';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { normalizeRoute } from 'src/app/model/user-navigation.interface';

export interface MegaMenuFeature {
  icon: string;
  label: string;
  route: string;
  name: string;
  active: boolean;
  class?: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

interface Module {
  id: number;
  name: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent implements OnInit {

  modules: Module[] = [];

  features: MegaMenuFeature[] = [
    {
      icon: 'assets/img/icons/admin__icon.png',
      label: 'Administration',
      route: '/modules/administration',
      name: 'administration',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/assure__icon.png',
      label: 'Assurée',
      route: '/modules/assuré',
      name: 'assure',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/param_icon.png',
      label: 'Paramétrage Auto',
      route: '/modules/paramétrage-auto',
      name: 'parametrage-auto',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/exp__icon.png',
      label: 'Exploitation Auto',
      route: '/modules/exploitation-auto',
      name: 'exploitation-auto',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/param_rd__icon.png',
      label: 'Parametrage TIRD',
      route: '/',
      name: 'parametrage-tird',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/rd__icon.png',
      label: 'Exploitation TIRD',
      route: '/',
      name: 'exploitation-tird',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/param_adp__icon.png',
      label: 'Paramétrage ADP',
      route: '/',
      name: 'parametrage-adp',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/adp_icon.png',
      label: 'Exploitation ADP',
      route: '/',
      name: 'exploitation-adp',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/compta__icon.png',
      label: 'Comptabilité',
      route: '/',
      name: 'compabilite',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/assurance_agricole.png',
      label: 'Assurance Agricole',
      route: '/',
      name: 'assurance-agricole',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    },
    {
      icon: 'assets/img/icons/reas__icon.png',
      label: 'Réassurrance',
      route: '/',
      name: 'reassurance',
      active: false,
      class: 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-not-allowed grayscale'
    }
  ];


  constructor(private popoverRef: PopoverRef<MegaMenuComponent>, private router: Router, private navService: NavigationService) { }

  onNavigate(moduleName: string) {
    if (this.features.find(
      item => normalizeRoute(item.name.toLocaleLowerCase()) === normalizeRoute(moduleName.toLocaleLowerCase())
    ).active) {
      localStorage.setItem('module', moduleName);
      this.navService.triggerItemsChange(normalizeRoute(moduleName));
      this.router.navigate(['/modules/' + normalizeRoute(moduleName)]);
      this.close();
    }
  }

  ngOnInit() {

    this.modules = JSON.parse(localStorage.getItem('modules-home'));

    this.features.forEach(item => {
      if (this.modules.find(module => normalizeRoute(module.name.toLocaleLowerCase()) === normalizeRoute(item.name.toLocaleLowerCase()))) {
        item.active = true;
        item.class = 'flex items-center justify-center text-dark p-1 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded no-underline cursor-pointer';
      }
    });
  }

  close() {
    this.popoverRef.close();
  }
}
