import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { PoolSessionService } from './pool-session.service';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import {PasswordResetData, UserToken} from "../model/auth.model";
import {jwtDecode} from "jwt-decode";

export interface UserDetails {
  id: number;
  token: string;
  username: string;
  prenom: string;
  nom: string;
  role: string;
  compagnies: string[];
  access: string[];
  options: string[];
  lier?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private poolSessionService: PoolSessionService) {}

  // Fonction pour effectuer l'inscription avec des en-têtes personnalisés
  register(user: any): Observable<any> {
    return this.http.post(`${environment.base_url}/auth/register`, user);
  }

  getUserDetails() {
    const id = localStorage.getItem('id');
    const username = localStorage.getItem('username');
    const prenom = localStorage.getItem('prenom');
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
    const compagnies = JSON.parse(localStorage.getItem('compagnies')) as [] || [];
    const access = localStorage.getItem('access');
    const lier = localStorage.getItem('lier') === 'true';
    return { id, username, role, compagnies, access, prenom, nom, lier };
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode<{ exp: number }>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  }

  login(credentials: any): Observable<any> {
    return this.http.post<any>(`${environment.base_url}/auth/authenticate`, credentials);
  }

  me(): Observable<any> {
    let auth_token = this.getSessionId();
    return this.http.post<any>(`${environment.base_url}/auth/me`, {'token' : auth_token});
  }

  initializePassword(passwordData: PasswordResetData): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer yourAccessToken',
      'Custom-Header': 'Custom-Value'
    });

    return this.http.post(`${environment.base_url}/initialize-password`, passwordData, { headers });
  }

  logout(): void {
    // Supprimez le token du localStorage en utilisant le service PoolSessionService
    this.poolSessionService.clearSession();
    this.router.navigate(['/auth/login']);
  }

  handleLoginResponse(response: UserDetails): UserDetails {
    if (response && response.token) {
      localStorage.setItem('accessToken', response.token);
      localStorage.setItem('username', response.username);
      localStorage.setItem('prenom', response.prenom);
      localStorage.setItem('nom', response.nom);
      localStorage.setItem('role', response.role);
      localStorage.setItem('compagnies', JSON.stringify(response.compagnies));
      localStorage.setItem('access', JSON.stringify(response.access));
      localStorage.setItem('options', JSON.stringify(response.options));
      localStorage.setItem('lier', response.lier ? 'true' : 'false');
    }
    return response;
  }

  checkSession(): boolean {
    if (localStorage.getItem('accessToken') === null) {
      return false;
    } else {
      return true;
    }
  }

  getSessionId() {
    return localStorage.getItem('accessToken');
  }

  /*updatePassword( passwordData: { oldPassword: string; newPassword: string }): Observable<any> {
    const id = localStorage.getItem('id');
    const url = `${environment.base_url}/auth/users/update/password/${id}`;
    return this.http.put(url, passwordData);
  }*/

    updatePassword(id: string, passwordData: { oldPassword: string; newPassword: string }): Observable<any> {
      const url = `${environment.base_url}/auth/users/update/password/${id}`;
      return this.http.put(url, passwordData);
    }
}

