import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {BreadcrumbsModule} from "../@vex/components/breadcrumbs/breadcrumbs.module";
import {PageLayoutModule} from "../@vex/components/page-layout/page-layout.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {JwtInterceptor} from "./service/JwtInterceptor";
import {getFrenchPaginatorIntl} from "./service/french-paginator-intl";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatDividerModule} from "@angular/material/divider";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {SecondaryToolbarModule} from "../@vex/components/secondary-toolbar/secondary-toolbar.module";
import {MatTabsModule} from "@angular/material/tabs";
import {MatListModule} from "@angular/material/list";
import { SearchModule } from "../@vex/components/search/search.module";
import { AdministrationModule } from './modules/administration/administration.module';
import { AssuresModule } from './modules/assures/assures.module';
import { ExploitationAutoModule } from './modules/exploitation-auto/exploitation-auto.module';
import { ParametresAutoModule } from './modules/parametres-auto/parametres-auto.module';
import { AuthModule } from './auth/auth.module';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';

@NgModule({
	declarations: [AppComponent, HomeComponent, ProfileComponent, ChangePasswordComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatIconModule,
		MatButtonModule,
		CommonModule,
		PageLayoutModule,
		BreadcrumbsModule,
		MatPaginatorModule,
		MatTableModule,
		MatSortModule,
		MatCheckboxModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		FormsModule,
		MatTooltipModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatRadioModule,
		MatSelectModule,
		MatMenuModule,
		MatDividerModule,
		// Vex
		VexModule,
		//CustomLayoutModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTabsModule,
		MatListModule,
		SecondaryToolbarModule,
		SearchModule,

	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl() },
		{ provide: LOCALE_ID, useValue: 'fr-FR' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
